@tailwind base;
@tailwind components;
@tailwind utilities;
/* src/index.css or src/assets/styles/global.css */
@font-face {
    font-family: 'CustomFont';
    src: url('../src/fonts/MichelleRegular-lgw7q.otf') format('truetype');
    
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'CFont';
    src: url('../src/fonts/Inter-Regular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'CCFont';
    src: url('../src/fonts/Inter-Bold.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'CcFont';
    src: url('../src/fonts/Inter-Thin.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  /* Apply styles to all Toastify toasts */
.Toastify__toast {
  min-width: 600px !important; /* Ensure a minimum width */
  /* max-width: 500px !important; Prevent excessive width */
  text-align: center;
  padding: 12px !important;
  border-radius: 8px !important;
}
